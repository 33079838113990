import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import productChartStyle from './productChartStyle'
import { useQuery } from '@apollo/react-hooks'
import classNames from 'classnames'
import { PRODUCT_CHART_SUMMER } from '../../../apollo/gql/PRODUCT_CHART_ALL_SUMMER'
import { PRODUCT_CHART_AUTUMN } from '../../../apollo/gql/PRODUCT_CHART_ALL_AUTUMN'
import { PRODUCT_CHART_WINTER } from '../../../apollo/gql/PRODUCT_CHART_ALL_WINTER'
import _ from 'lodash';

const ProductChart = props => {
  const { classes, locale, affiliateCountry, amount, humanType, priceRangeMax, priceRangeMin, season } = props


  console.log('season', season)

  const query = season === 'SUMMER' ? PRODUCT_CHART_SUMMER :
    season === 'AUTUMN' ? PRODUCT_CHART_AUTUMN :
      season === 'WINTER' ? PRODUCT_CHART_WINTER : PRODUCT_CHART_AUTUMN

  const { loading, error, data } = useQuery(query
    , {
      variables: {
        afilCountry: affiliateCountry,
        orderBy: 'price_ASC',
        productAmount: amount,
        humanType: humanType,
        priceMin: priceRangeMax,
        priceMax: priceRangeMin,

      },
    },
  )
  if (loading) return 'Loading...'
  if (error) return `Error! ${error.message}`


  return (
    <div className={classes.root}>

      {/*{JSON.stringify(data)}*/}
      {data ? _.uniqBy(data.wetsuitsConnection.edges, function (e) {
        return e.node.productName;
      }).map((item, index) => {
        return (
          <div style={{
            display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center',
            alignItems: 'center',
          }}>
            {/*<p>{index + 1} # </p>*/}
            <a href={item.node.afilLink} target={'_blank'}
               className={classes.productLinkWrapper}>
              <h3 className={classes.header}>{index + 1}. <span
                style={{ fontWeight: 'bold' }}>{item.node.brand.displayName} - </span> {item.node.productName} </h3>

              <div className={classes.wrapper}>
                <img alt="..." src={item.node.smallImage}
                     height={200}

                     className={classes.image}/>

                <div className={classes.textWrapper}>
                  <h4 style={{ margin: 0, padding: 0 }} className={classes.H3}>
                    <span style={{ paddingRight: 15 }}>{locale === 'de' ? 'Preis:' : 'Price:'} </span> <span
                    className={classNames(classes.price, item.node.reducedPrice ? classes.priceReduced : null)}
                    style={{ paddingRight: 15 }}>
                    {item.node.price}{item.node.currency === 'GBP' ? '£' : item.node.currency === 'EUR' ? '€' : ''}
                    <span className={classNames(classes.price, classes.oldPrice)}>
                        {item.node.oldPrice}{item.node.currency === 'GBP' ? '£' : item.node.currency === 'EUR' ? '€' : ''}
                      </span>
                    </span>

                  </h4>
                  <h4 style={{ margin: 0 }} className={classes.H3}>
                    {locale === 'de' ? 'Verschluss:' : 'Zip Typ:'} {
                    item.node.frontzip ||
                    item.node.backzip ||
                    item.node.zipless ? <span
                      className={classes.productNameWrapperSpan}>
                     {item.node.frontzip ? 'Frontzip' : null}
                      {item.node.backzip ? 'Backzip' : null}
                      {item.node.zipless ? 'Zipless' : null} </span> : null}
                  </h4>
                  <span>
                      <h4 style={{ margin: 0, padding: 0 }} className={classes.H3}>
                   {locale === 'de' ? 'Beschreibung:' : 'Description:'}
                  </h4>
                  <p style={{ paddingRight: 15, fontSize: 18, marginTop: 0, paddingTop: 0 }}>
                    {item.node.description}
                  </p>
                  </span>


                </div>


              </div>

            </a>
          </div>
        )
      }) : null}

      {/*<h3>Product Chart</h3>*/}
      {/*<p>{locale}</p>*/}
      {/*<p>{affiliateCountry}</p>*/}
      {/*<p>{amount}</p>*/}
      {/*<p>{humanType}</p>*/}
      {/*<p>{priceRangeMax}</p>*/}
      {/*<p>{priceRangeMin}</p>*/}
      {/*<p>{season}</p>*/}

    </div>

  )

  // return (
  //   <div className={classes.root}>
  //
  //
  //
  //     <h3>Product Chart</h3>
  //     <p>{locale}</p>
  //     <p>{affiliateCountry}</p>
  //     <p>{amount}</p>
  //     <p>{humanType}</p>
  //     <p>{priceRangeMax}</p>
  //     <p>{priceRangeMin}</p>
  //     <p>{season}</p>
  //
  //   </div>
  // )
}
ProductChart.defaultProps = {
  affiliateCountry: 'UK',
  amount: 10,
  humanType: 'MEN',
  priceRangeMax: 200,
  priceRangeMin: 100,
  season: 'ALL',
}
//
ProductChart.propTypes = {}

export default withStyles(productChartStyle)(ProductChart)