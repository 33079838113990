import { dangerColor, H1, H3, H4, P, poppinsHeader, primaryColor } from 'assets/jss/material-kit-react.jsx'

const productChartStyle = {
  root: {

    // backgroundColor: 'rgba(255,239,239,0.9)',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageStyles: {
    height: '100%',
  },
  oldPrice: {
    textDecoration: 'line-through',
    paddingLeft: 15,
  },
  H3: {
    ...H3,
  },
  H4: {
    ...H4,
  },
  image: {

    maxHeight: 200,
    maxwidth: 300,
    marginTop: 15,
    objectFit: 'contain',
  },
  productLinkWrapper: {
    width: '100%', display: 'flex', flexDirection: 'column',
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    width: '100%',
    height: '100%',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    '@media (max-width: 768px)': {
      justifyContent: 'center',
      alignItems: 'center',
      padding: 15,
      textAlign: 'center',
    },

  },
  wrapper: {
    paddingTop: 10,
    paddingBottom: 10,
    display: 'flex',
    flexDirection: 'row',
    width: '100%', justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid black',
    marginRight: 15,
    marginBottom: 15,
    transition: '0.3s ease',
    '&:hover': {
      boxShadow: '0 4px 20px 0px rgba(0, 0, 0, 0.2)',

    },
    '@media (max-width: 768px)': {
      flexDirection: 'column',
    },
  },
  H1: {
    ...H1,
    margin: 0,
    padding: 0,
  },
  P: {
    ...P,
    lineHeight: 1.45,
    color: 'white',
  },
  header: {
    textAlign: 'left',
    marginBottom: 0,
  },
  poppins: {
    ...poppinsHeader,
  },
  poppinsHeader: {
    ...poppinsHeader,
    margin: 0, padding: 0,
    marginBottom: 20,
  },
  subTitle: { padding: 0, margin: 0 },

  buttonMainWrapper: {
    height: '100%',
    width: '40%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    // backgroundColor: 'red',
    '@media (max-width: 1060px)': {

      width: '100%',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    // backgroundColor: 'khaki',
  },
  price: {
    ...poppinsHeader,
    margin: 0,
    padding: 0,
    fontSize: 18,
    fontWeight: 'bold',
    color: primaryColor,

  },
  priceReduced: {
    color: dangerColor,
    paddingRight: 15,
  },
  productNameWrapperSpan: {
    border: 'solid #1a1a1a 1px',
    borderRadius: 99,
    paddingLeft: 6,
    paddingRight: 6,
    // marginRight: 5,
    marginLeft: 8,
    fontWeight: '600',
    fontSize: 17,
  },
  buttonWrapper: {
    height: 175,
    width: 175,
    backgroundColor: 'black',
    borderRadius: 99,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 1s ease',
    '&:hover': {
      transform: 'scale(1.2)',
    },
    '@media (max-width: 1060px)': {
      height: 120,
      width: 120,
    },

  },
  sectionTitleTop: {
    margin: 0,
    padding: 0,
    fontFamily: '\'Poppins\', sans-serif',
    fontSize: 12,
    textTransform: 'uppercase',

    letterSpacing: '3px',
  },
  sectionTitleBottom: {
    margin: 0,
    padding: 0,
    fontFamily: '"Cormorant Garamond", "Helvetica", "Arial", sans-serif',
    letterSpacing: '-1px',
    lineHeight: 1.06,
    marginBottom: 25,
    paddingBottom: 3,
  },
}

export default productChartStyle
