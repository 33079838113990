import React from 'react'
import DE from './DE'
import EN from './EN'

export default function CtaWetsuitFinderProvider (props) {

  return (
    <>
      {props.actlang === 'de' ?
        <DE actLang={'de'}/> : <EN actLang={'en'}/>}
    </>
  )


}


