import gql from 'graphql-tag'

export const PRODUCT_CHART_AUTUMN= gql`
    query wetsuitsConnection(
        $humanType: String!
        $afilCountry:String!
        $orderBy: WetsuitOrderByInput!

        $productAmount: Int!

        $priceMin: Float!
        $priceMax: Float!
        

    ) {
        wetsuitsConnection(
            first:$productAmount
            orderBy:$orderBy
            where:{
                reducedPrice:true
                price_lt:$priceMin
                price_gt:$priceMax
                humanType:{name:$humanType}
         
                afilCountry:$afilCountry
                OR:[
                    {
                        thickness25:true,
                        thickness30:true
                    },
                    {
                        thickness30:true,
                        thickness15:false,
                        thickness10:false,
                        thickness20:false,
                    },
                    {
                        thickness35:true,
                        thickness15:false,
                        thickness10:false,
                    },
                    {
                        thickness40:true,
                        thicknessR4:false,
                    },
                    {
                        thickness50:true,
                        thickness15:false,
                    },
                    {
                        thickness55:true,
                        thickness15:false,
                    },
                    {
                        thicknessR2:true,
                    },
                    {
                        thicknessR3:true,
                    },
                ]
            }){

            edges{node{
                id
                size{
                    name
                }
                brand{
                    name
                    displayName
                }
                updatedAt
                productName
                price
                frontzip
                backzip
                zipless
                afilLink
                bigImage
                smallImage
                reducedPrice
                oldPrice
                currency
                afilPartner
                description

            }}

        }
    }


`