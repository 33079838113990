import React from 'react'
import Footer from '../../components/Footer/Footer'
import { graphql } from 'gatsby'
import Section from '../../components/Section/Section'
import withStyles from '@material-ui/core/styles/withStyles'
import privacyStatementStyle from './privacyStatementStyle'
import PageHeader from '../../components/PageHeader/PageHeader'
import Content from '../../elements/Content/Content'
import HeaderProvider from '../../components/Header/HeaderProvider'
import SEO from '../../components/SEO'

const PrivacyStatement = props => {


  const { classes, data, pageContext } = props

  const modified = []
  data.datoCmsPrivacyStatement._allUrlLocales.map(item => {
    if (item.locale === 'de') {
      modified.push({ 'locale': item.locale, 'value': item.value })
    } else {
      modified.push({ 'locale': item.locale, 'value': item.value })
    }
  })

  const { featuredImage, title, meta, _allUrlLocales, content } = data.datoCmsPrivacyStatement

  return (
    <div className={classes.backgroundColor}>
      <SEO
        seoMetaTags={data.datoCmsPrivacyStatement.seoMetaTags.tags}
        actLang={pageContext.locale}
        localURLS={modified}
      />
      <HeaderProvider
        localURLS={_allUrlLocales}
        actLang={pageContext.locale}
        SEOtitle={title}
        SEOimage={featuredImage.fluid.src}
        SEOdescription={''}
      />
      <PageHeader
        big
        image={featuredImage.fluid}
        title={title}
        date={meta.updatedAt}
      />
      <Section paddingTop={0} flexDirRow={true}>
        <Content content={content}/>
      </Section>
      <Footer
        actLang={props.pageContext.locale}
      />
    </div>
  )
}


export default withStyles(privacyStatementStyle)(PrivacyStatement)

export const data = graphql`
    query($locale: String!) {
        datoCmsPrivacyStatement(locale:{eq:$locale})   {
            seoMetaTags{
                tags
            }
            _allUrlLocales{locale value}
            title
            featuredImage{
                alt
                fluid(maxWidth:705){
                    src
                    ...GatsbyDatoCmsFluid_noBase64
                }
            }
            meta {
                updatedAt(formatString: "MM-DD-YYYY")
            }
            content {
                __typename
                ... on DatoCmsYoutubeVideo {
                    link
                }
                ... on DatoCmsText {
                    text
                }
                ... on DatoCmsBannerWithBgImage {
                    title
                    text
                    image{
                        alt
                        fluid(maxWidth:1800){
                            ...GatsbyDatoCmsFluid_noBase64
                        }
                    }
                    buttonTitle
                    buttonLink{
                        ...on DatoCmsSizeFinder{
                            url
                        }
                        ...on DatoCmsWetsuitFinder{
                            url
                        }

                    }

                }
                ... on DatoCmsImage {
                    image {
                        alt
                        fluid(maxWidth: 705) {
                            src
                            ...GatsbyDatoCmsFluid_noBase64
                        }
                    }
                }
            }
        }
    }
`