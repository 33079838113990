import React from 'react'

import { graphql, useStaticQuery } from 'gatsby'
import CtaWetsuitFinder from './CtaWetsuitFinder'


const EN = (props) => {

  const data = useStaticQuery(graphql`
      query {
          datoCmsElementCtaWetsuitFinder(locale: { eq: "en" }) {
              title
              subtitle
              buttonLabel
              backgroundImage {
                  alt
                  fluid(maxWidth:705){
                      src
                      ...GatsbyDatoCmsFluid_noBase64
                  }
              }
          }

      }
  `)


  return (
    <CtaWetsuitFinder data={data} actLang={props.actLang}/>
  )
}

export default EN

