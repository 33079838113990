import React from 'react'


import withStyles from '@material-ui/core/styles/withStyles'


import Img from 'gatsby-image'

import contentStyle from './contentStyle'
import BannerWithImg from '../BannerWithImg/BannerWithImg'
import CtaWetsuitFinderProvider from '../CtaWetsuitFinder/CtaWetsuitFinderProvider'
import ProductChart from './ProductChart.jsx/ProductChart'


const Content = (props) => {


  const { classes } = props
  return (
    <div className={classes.root}>
      {props.content ?
        props.content.map(item => {
          if (item.__typename === 'DatoCmsH2') {
            return <h2 className={classes.h2style}>{item.heading}</h2>
          }
          if (item.__typename === 'DatoCmsH3') {
            return <h3 className={classes.h3style}>{item.heading}</h3>
          }
          if (item.__typename === 'DatoCmsH4') {
            return <h4 className={classes.h4style}>{item.heading}</h4>
          }
          if (item.__typename === 'DatoCmsH5') {
            return <h5 className={classes.h5style}>{item.heading}</h5>
          }
          if (item.__typename === 'DatoCmsH6') {
            return <h6 className={classes.h6style}>{item.heading}</h6>
          }
          if (item.__typename === 'DatoCmsText') {
            return <div>
              <div
                className={classes.contentWrapper}
                dangerouslySetInnerHTML={{
                  __html: item.text,
                }}
              />
            </div>
          }
          if (item.__typename === 'DatoCmsBannerWithBgImage') {
            return <div>

              <BannerWithImg
                title={item.title}
                text={item.text}
                image={item.image}
                buttonTitle={item.buttonTitle}
                link={item.buttonLink.url}
              />
            </div>
          }
          if (item.__typename === 'DatoCmsCtaWetsuitFinder') {
            return <div>
              <CtaWetsuitFinderProvider actlang={item.locale}/>
            </div>
          }
          if (item.__typename === 'DatoCmsProductChart') {
            return <div>
              <ProductChart
                locale={item.locale}
                affiliateCountry={item.affiliateCountry}
                amount={item.amount}
                humanType={item.humanType}
                priceRangeMax={item.priceRangeMax}
                priceRangeMin={item.priceRangeMin}
                season={item.season}

              />
            </div>
          }
          if (item.__typename === 'DatoCmsImage') {
            return (

              <Img
                height={'100%'}
                fluid={item.image.fluid}
                alt={item.image.alt}
                backgroundColor={'#f2f2f2'}
                style={{ maxHeight: 350, marginTop: 30, marginBottom: 30 }}
              />

            )


          }
          if (item.__typename === 'DatoCmsYoutubeVideo') {
            return (
              <iframe width="100%"

                      src={'https://www.youtube.com/embed/' + item.link.slice(item.link.search('v=') + 2, item.link.length)}
                      className={classes.youtube}
                      frameBorder="0"
                      allowFullScreen
              >
              </iframe>
            )


          } else {
            return null
          }

        })

        : null}
    </div>
  )

}

export default withStyles(contentStyle)(Content)

