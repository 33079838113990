import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import ctaWetsuitFinderStyle from './ctaWetsuitFinderStyle'
import BackgroundImage from 'gatsby-background-image'
import ButtonOutlinedGatsbyLink from '../../components/ButtonOutlinedGatsbyLink/ButtonOutlinedGatsbyLink'

const CtaWetsuitFinder = props => {


  const { classes, data, actLang,test } = props
  return (
    <div className={classes.root}>

      <BackgroundImage className={classes.imageStyles}
                       fluid={data.datoCmsElementCtaWetsuitFinder.backgroundImage.fluid}>
        <div style={{ width: '100%', backgroundColor: 'rgba(255,239,239,0.8)' }}>

          <div className={classes.wrapper}>
            <div className={classes.textWrapper}>
              <p className={classes.poppinsHeader}>{data.datoCmsElementCtaWetsuitFinder.title}</p>
              <p className={classes.H2}>
                {data.datoCmsElementCtaWetsuitFinder.subtitle}
              </p>
            </div>
            <ButtonOutlinedGatsbyLink link={actLang === 'de' ? '/de/wetsuit-finder' : '/wetsuit-finder'}
                                      title={data.datoCmsElementCtaWetsuitFinder.buttonLabel}/>
          </div>

        </div>
      </BackgroundImage>


    </div>

  )
}


export default withStyles(ctaWetsuitFinderStyle)(CtaWetsuitFinder)

