import gql from 'graphql-tag'

export const PRODUCT_CHART_SUMMER = gql`
    query wetsuitsConnection(
        $humanType: String!
        $afilCountry:String!
        $orderBy: WetsuitOrderByInput!

        $productAmount: Int!

        $priceMin: Float!
        $priceMax: Float!
        

    ) {
        wetsuitsConnection(
            first:$productAmount
            orderBy:$orderBy
            where:{
                reducedPrice:true
                price_lt:$priceMin
                price_gt:$priceMax
                humanType:{name:$humanType}
   
                afilCountry:$afilCountry
                OR:[
                    {
                        thickness15:true
                    },
                    {
                        thickness20:true
                    },
                    {
                        thickness20:true
                        thickness15:true
                    },
                    {
                        thickness20:true
                        thickness10:true
                    },
                    {
                        thickness25:true
                    },
                    {
                        thickness30:true
                        thicknessR3:false
                        thickness40:false
                        thickness50:false
                    },
                    {
                        thicknessR1:true
                    },
                    {
                        thicknessR2:true
                    },
                    {
                        shorty:true
                    },
                    {
                        longJohnJane:true
                    },
                ],
            }){

            edges{node{
                id
                size{
                    name
                }
                brand{
                    name
                    displayName
                }
                updatedAt
                productName
                price
                frontzip
                backzip
                zipless
                afilLink
                bigImage
                smallImage
                reducedPrice
                oldPrice
                currency
                afilPartner
                description

            }}

        }
    }


`